import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import DisplayDateProvider from '../../../components/DisplayDateProvider/DisplayDateProvider'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import EmptyMap from '../../../components/Map/emptyMap'
import Spinner from '../../../components/Loader/Spinner'
import MapLegend from '../cdcMapLegendHorizontal'

import styles from './MapPage.module.scss'
import dayjs from 'dayjs'
import SideTableProvider from './SideTableProvider'
import ErrorLogs from '../../../components/ErrorBoundary/errorlogs'
import { useAuthState } from '../../../components/AuthProvider'
import ProviderDetails from './ProviderDetails'
import ProviderMapMarker from '../ProviderMapMarker'
import { lastweekEndDate } from './../dates'
const DashboardMap = ({
  geo_type,
  geo_parent,
  zoomLocation,
  sidetableData,
}) => {
  const geojson = useRef()
  const mapRef = useRef()
  const [getforecast_level, setforecast_level] = useState()
  const { user } = useAuthState()

  const [getProviderDropDown, setProviderDropDown] = useState()

  // const [getAllProvider, setAllProvider] = useState()
  const [getStats, setStats] = useState()
  const location = useLocation()
  const { loc, area } = useParams()
  const hash = location.hash

  const [activeCbsa, setActiveCbsa] = useState()

  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const { dataStartDate, dataEndDate, regionSelected, setRegionSelected } =
    useGeoState()
  const [getProvider, setProvider] = useState()
  if (area === 'ZIP3') {
    setRegionSelected(area.toLowerCase())
  }
 // const lastweekEndDate = dayjs().day(-1).format('YYYY-MM-DD')
  const { data: msaData, error: MapmsaError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'fluMapThreshold',
    `ZIP3/?geo_parent=${geo_parent}&geoid=${loc}`
  )

  useEffect(() => {
    if (msaData !== null && msaData !== undefined) {
      if (msaData.response_code === 200) {
        ErrorLogs({
          message_type: 'Activity',
          error_details: 'Msa Dashboard success',
          user: user,
        })
        setforecast_level(msaData.response_data)
      } else if (msaData.response_code === 404) {
        setforecast_level(msaData.response_data)
        ErrorLogs({
          message_type: 'Error',
          error_details: `${msaData.response_subject}, ${msaData.response_message},for ${geo_parent}`,
          user: user,
        })
      } else {
        setErrorCode(msaData.response_code)
        setErrorMessage(msaData.response_message)
        setErrorSubject(msaData.response_subject)
        seterrorOccured(true)
      }
    }
  }, [msaData])

  // Active Metro Areas
  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    'fluLocations',
    'activelocations?geo_type=ZIP3&geo_parent_code=' + geo_parent
  )

  useEffect(() => {
    if (activeCbsasData !== null && activeCbsasData !== undefined) {
      setActiveCbsa(activeCbsasData)
    }
  }, [activeCbsasData])

  let type = hash.length > 0 ? hash.replace('#', '') : ''

  // const { data: zip3Stats, error: zip3DataError } = useGeoFetch(
  //   lastweekEndDate,
  //   lastweekEndDate,
  //   'fluStats',
  //   'ZIP3/'
  // )

  // useEffect(() => {
  //   if (zip3Stats !== null && zip3Stats !== undefined) {
  //     if (zip3Stats.response_code === 200) {
  //       setZIP3Stats(zip3Stats.response_data)
  //     } else if (zip3Stats.response_code === 404) {
  //       setZIP3Stats(zip3Stats.response_data)
  //       ErrorLogs({
  //         message_type: 'Error',
  //         error_details: `${zip3Stats.response_subject}, ${zip3Stats.response_message},for ${geo_parent}`,
  //         user: user,
  //       })
  //     } else {
  //       // setErrorCode(zip3Stats.response_code)
  //       // setErrorMessage(zip3Stats.response_message)
  //       // setErrorSubject(zip3Stats.response_subject)
  //       // seterrorOccured(true)
  //     }
  //   }
  // }, [zip3Stats])

  const { data: stats, error: stateDataError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProviders',
    geo_parent + `/${loc}?disease_type=FLU&filter=${type}&geotype=${geo_type}`
  )

  useEffect(() => {
    if (stats !== null && stats !== undefined) {
      if (stats.response_code === 200) {
        setStats(stats.response_data)
      } else {
        setErrorCode(stats.response_code)
        setErrorMessage(stats.response_message)
        setErrorSubject(stats.response_subject)
        seterrorOccured(true)
      }
    }
  }, [stats])



  const { data: providerDropDown, error: dropdownError } = useGeoFetch(
    lastweekEndDate,
    lastweekEndDate,
    'rsvProvidersFilter',
    geo_parent + `/${loc}`
  )
  useEffect(() => {
    if (providerDropDown !== null && providerDropDown !== undefined) {
      if (providerDropDown.response_code === 200) {
        setProviderDropDown(providerDropDown.response_data)
      } else {
        setErrorCode(providerDropDown.response_code)
        setErrorMessage(providerDropDown.response_message)
        setErrorSubject(providerDropDown.response_subject)
        seterrorOccured(true)
      }
    }
  }, [providerDropDown])

  
  const chooseMessage = (data) => {
    setProvider(data)
  }
  const [getzip3Json, setzip3Json] = useState()
  useEffect(() => {
    let url = `${window.location.origin}/three_dig_zips.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setzip3Json(data)
      })
      .catch(function (err) {
        console.log(err, ' error file load us_hrr_values ')
      })
  }, [1])
  if (errorOccured || MapmsaError || activeCbsasDataError) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  var checkUndefine = zoomLocation
  var zoomForZipState =
    zoomLocation === undefined && regionSelected === 'zip3' ? 0 : 2
  zoomLocation = zoomLocation === undefined ? geo_parent : zoomLocation

  return (
    <div className={`${styles.base}`}>
      <div className={`${styles.cbsasPanel}`}>
        <>
          {regionSelected === 'zip3' && (
            <>
              {getStats !== undefined && getProviderDropDown !== undefined ? (
                <SideTableProvider
                  dropdownData={getProviderDropDown}
                  stateData={getStats}
                  chooseMessage={chooseMessage}
                  //callsideTable={callsideTable}
                  mapRef={mapRef}
                />
              ) : (
                <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </>
      </div>
      <div className={`${styles.map}`}>
        {regionSelected === 'zip3' && (
          <>
            {getforecast_level !== undefined && activeCbsa !== undefined ? (
              <>
                {getProvider && (
                  <ProviderDetails
                    providerData={getProvider}
                    chooseMessage={chooseMessage}
                  />
                )}
                {getStats && (
                  <>
                    <div className={`${styles.providerHeaderCollection}`}>
                      <DisplayDateProvider
                        textShow={'CDO Locations and Forecast for Epiweek '}
                        showWeek={true}
                        lastweekEndDate={lastweekEndDate}
                      />
                    </div>
                    {getStats && getforecast_level && (
                      <MapLegend
                        geojson={geojson}
                        provider={true}
                        thresholdVal={getforecast_level}
                      />
                    )}
                    {getforecast_level && (
                      <ProviderMapMarker
                        geojson={geojson}
                        thresholdVal={getforecast_level}
                        providers={getStats}
                        geoid={loc}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <EmptyMap />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardMap
